.status-badge {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.3rem;
  font-size: 50%;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--secondary);

  &.draft {
    border-color: var(--warning);
    background-color: transparent;
  }

  &.submitted {
    border-color: var(--warning);
    background-color: var(--warning);
  }

  &.refused {
    border-color: var(--danger);
    background-color: var(--danger);
  }

  &.accepted {
    border-color: var(--primary);
    background-color: var(--primary);
  }

  &.empty {
    border-color: var(--secondary);
    background-color: transparent;
  }
}

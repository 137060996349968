@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900");
[class^='img--'] {
  max-width: 80%;
  display: block;
  margin: 0 auto 1em auto; }

.img--loading {
  animation-name: glowing-transparency;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate; }

.img--loaded {
  opacity: 0.9;
  transition: opacity 0.3s; }

@keyframes glowing-transparency {
  from {
    opacity: 0.8; }
  to {
    opacity: 0.5; } }

.toolbar {
  background-color: var(--light);
  padding: 0.3em;
  display: flex;
  flex-direction: row;
  position: sticky;
  z-index: 50;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }
  .toolbar .toolbar__btn {
    padding: 0.3em;
    border: none;
    padding: 0 0.5em; }
    .toolbar .toolbar__btn:hover,
    .toolbar .toolbar__btn label:hover {
      cursor: pointer; }
    .toolbar .toolbar__btn label {
      margin-bottom: 0; }

@media (min-width: 576px) {
  .toolbar {
    top: 59px; } }

@media (max-width: 575.98px) {
  .toolbar {
    top: 176px; } }
